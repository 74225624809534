import './app.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React from 'react';
import Navbar from './pages/nav-foot/Navbar';
import Footer from "./pages/nav-foot/Footer";
import ScrollToTop from './helpers/ScrollToTop';
import { AllRoutes } from './routes/AllRoutes';
import { useEffect } from 'react';
import { useState } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'


function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#3D3C3C');
  useEffect(() => {
    if (window.location.hostname === 'goblck.in'||window.location.hostname === 'www.goblck.in') {
        window.location.replace('https://blckluxury.com' + window.location.pathname);
    }
}, []);
  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowScrollButton(true) : setShowScrollButton(false);
    };

   
  
    const handleBackgroundColorChange = () => {
      const scrollY = window.pageYOffset;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = scrollY / maxScroll;
      
      // Interpolating from rgb(61, 60, 60) to rgb(0, 0, 0)
      const startColor = { r: 61, g: 60, b: 60 };
      const endColor = { r: 0, g: 0, b: 0 };
      
      const r = Math.round(startColor.r + (endColor.r - startColor.r) * scrollPercentage);
      const g = Math.round(startColor.g + (endColor.g - startColor.g) * scrollPercentage);
      const b = Math.round(startColor.b + (endColor.b - startColor.b) * scrollPercentage);
      
      setBackgroundColor(`rgb(${r}, ${g}, ${b})`);
    };
  
    window.addEventListener('scroll', handleScrollButtonVisibility);
    window.addEventListener('scroll', handleBackgroundColorChange);
  
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
      window.removeEventListener('scroll', handleBackgroundColorChange);
    };
  }, []); 
   useEffect(()=>{
    // const resizeOps = () => {
    //   document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
    // };
  
    // resizeOps();
    // window.addEventListener("resize", resizeOps);
    const handleScrollButtonVisibility = () =>{
      window.pageYOffset > 300 ? setShowScrollButton(true):setShowScrollButton(false)
    }
    window.addEventListener('scroll',handleScrollButtonVisibility);
    return () =>{
      window.addEventListener('scroll',handleScrollButtonVisibility);
    }
  },[])
  const handleScrollToTop = () =>{
    window.scrollTo({top:0, behavior:'smooth'})
  };
  let location = useLocation()
  
  return (
    <React.Fragment>
      <div className='app' style={{ backgroundColor }}>
        <Navbar />
        
  <Footer />
      </div>
         <FloatingWhatsApp  
         phoneNumber="+919900085611" 
        accountName="GoBlck" 
        statusMessage="Typically replies within a few hours" 
        chatMessage="Hello! How can we help you?" 
        avatar="https://blckluxury.com/logo.png" />
      {showScrollButton && <div className='scroll_button' onClick={handleScrollToTop}>▲</div>}

    </React.Fragment>
  );
}

export default App;